const _temp0 = require("../img/productType/53.png");
const _temp1 = require("../img/productType/54.png");
const _temp2 = require("../img/productType/55.png");
const _temp3 = require("../img/productType/58.png");
const _temp4 = require("../img/productType/59.png");
const _temp5 = require("../img/productType/glas1.png");
const _temp6 = require("../img/productType/glas1_alt.png");
const _temp7 = require("../img/productType/glas2.png");
const _temp8 = require("../img/productType/glas3.png");
const _temp9 = require("../img/productType/glas4.png");
const _temp10 = require("../img/productType/rot.png");
module.exports = {
  "53": _temp0,
  "54": _temp1,
  "55": _temp2,
  "58": _temp3,
  "59": _temp4,
  "glas1": _temp5,
  "glas1_alt": _temp6,
  "glas2": _temp7,
  "glas3": _temp8,
  "glas4": _temp9,
  "rot": _temp10
}