//local storage
export class Storage {
  // Reset cart because of possible corrupted cart due to bug
  static resetLocalStorageOnce() {
    if (localStorage.getItem("util"))
      if (JSON.parse(localStorage.getItem("util")).cartReset) return;
    localStorage.clear();
    localStorage.setItem("util", JSON.stringify({ cartReset: true }));
    location.reload();
    console.log("reset");
  }

  static saveProducts(products) {
    localStorage.setItem("products2024", JSON.stringify(products));
  }
  static getProduct(id) {
    let products = JSON.parse(localStorage.getItem("products2024"));
    return products.find((product) => product.id === id);
  }
  static saveCart(cart) {
    localStorage.setItem("cart2024", JSON.stringify(cart));
  }
  static getCart() {
    return localStorage.getItem("cart2024")
      ? JSON.parse(localStorage.getItem("cart2024"))
      : [];
  }
}

export class Util {
  static calcShippingFee(cart) {
    let tempTotal = 0;
    let packageSpaceTotal = 0;
    cart.map((item) => {
      tempTotal += item.preis * item.amount;
      packageSpaceTotal += item.amount * parseFloat(item.packageSpace);
    });
    let amount = packageSpaceTotal;
    let bottles = Math.ceil(amount);
    while (bottles % 6 != 0) bottles++;
    let packageTwelve = Math.floor(bottles / 12);
    let packageSix = bottles % 12 == 0 ? 0 : 1;
    if (tempTotal >= 300) return 0;
    if (packageTwelve) return packageTwelve * 8.9 + packageSix * 7.7;
    else return 7.7;
  }

  // Make "0" for no Discount
  static calcDiscount(cart) {
    // quick and dirty bug fix to get code here
    let code = document.getElementById("rabattcode").value;
    let total = 0;
    cart.forEach((product) => {
      if (product.discountCode == "");
      else if (product.discountCode == code)
        total += product.amount * product.preis * product.discount;
    });
    return total;
  }

  static calcTotal(cart) {
    let amount = 0;
    let total = 0;

    cart.map((item) => {
      amount += item.amount;
      total += item.preis * item.amount;
    });

    let fee = Util.calcShippingFee(cart);
    total += fee;
    total -= this.calcDiscount(cart);

    return total;
  }
  // check every product in the cart if it is still sold in the shop
  // remove from cart if not
  static clearCartFromOldProducts(cart, allProducts) {
    cart.forEach((product) => {
      if (!allProducts.find((element) => element.id == product.id)) {
        //remove item from the cart
        cart = cart.filter((item) => item.id !== product.id);
        console.log(product.id + " was removed from the cart");
      }
    });
    return cart;
  }
}

export class Form {
  static getFormData() {
    return {
      firstName: document.getElementById("firstname").value,
      lastName: document.getElementById("lastname").value,
      zip: document.getElementById("zip").value,
      town: document.getElementById("town").value,
      street: document.getElementById("street").value,
      number: document.getElementById("number").value,
      email: document.getElementById("email").value,
      phone: document.getElementById("phone").value,
      isDifAddress: document.getElementById("sameadr").checked,
      firstNameAlt: document.getElementById("firstname-alt").value,
      lastNameAlt: document.getElementById("lastname-alt").value,
      zipAlt: document.getElementById("zip-alt").value,
      townAlt: document.getElementById("town-alt").value,
      streetAlt: document.getElementById("street-alt").value,
      numberAlt: document.getElementById("number-alt").value,
      note: document.getElementById("anmerkung").value,
      geschenk: document.getElementById("geschenk").checked,
      geschenktext: document.getElementById("geschenktext").value,
      widerruf: document.getElementById("widerruf").checked,
      datenschutz: document.getElementById("datenschutz-notwendig").checked,
      newsletter: document.getElementById("newsletter").checked,
      agb: document.getElementById("agb").checked,
      dpd: document.getElementById("DPD").checked,
      adult: document.getElementById("alter").checked,
      payment: document.querySelector('input[name = "zahlungsRadio"]:checked')
        .value,
      rabattcode: document.getElementById("rabattcode").value,
    };
  }

  static afterFormSubmit() {
    document.getElementById("sendMailForm").reset();
    Storage.saveCart([]);
    window.location.href = "../danke.html";
  }
}
