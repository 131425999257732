import jquery from "jquery";
window.$ = window.jQuery = jquery; // notice the definition of global variables here
import Isotope from "isotope-layout";

export var initFilter = function () {
  $(".hideme").each(function (index, item) {
    if (isElementInViewport(item)) {
      $(item).addClass("visible");
    } else {
      $(item).removeClass("visible");
    }
  });

  const $grid = new Isotope(".steckbriefe", {
    // options...
    itemSelector: "article",
  });

  // https://isotope.metafizzy.co/filtering.html
  var filterValueArt = "",
    filterValueGeschmack = "",
    filterValueRebsorte = "",
    filterValueQualitätsstufe = "";

  $(".btn-art").on("click", "button", function () {
    filterValueArt = $(this).attr("data-filter");
    $grid.arrange({
      filter:
        filterValueArt +
        filterValueGeschmack +
        filterValueRebsorte +
        filterValueQualitätsstufe,
    });
    $(".btn-art [data-filter]").removeClass("active");
    $(".btn-art [data-filter='" + filterValueArt + "']").addClass("active");
  });
  $(".btn-geschmack").on("click", "button", function () {
    filterValueGeschmack = $(this).attr("data-filter");
    $grid.arrange({
      filter:
        filterValueArt +
        filterValueGeschmack +
        filterValueRebsorte +
        filterValueQualitätsstufe,
    });
    $(".btn-geschmack [data-filter]").removeClass("active");
    $(".btn-geschmack [data-filter='" + filterValueGeschmack + "']").addClass(
      "active"
    );
  });
  $(".btn-rebsorte").on("click", "button", function () {
    filterValueRebsorte = $(this).attr("data-filter");
    $grid.arrange({
      filter:
        filterValueArt +
        filterValueGeschmack +
        filterValueRebsorte +
        filterValueQualitätsstufe,
    });
    $(".btn-rebsorte [data-filter]").removeClass("active");
    $(".btn-rebsorte [data-filter='" + filterValueRebsorte + "']").addClass(
      "active"
    );
  });
  $(".btn-qualitätsstufe").on("click", "button", function () {
    filterValueQualitätsstufe = $(this).attr("data-filter");
    $grid.arrange({
      filter:
        filterValueArt +
        filterValueGeschmack +
        filterValueRebsorte +
        filterValueQualitätsstufe,
    });
    $(".btn-qualitätsstufe [data-filter]").removeClass("active");
    $(
      ".btn-qualitätsstufe [data-filter='" + filterValueQualitätsstufe + "']"
    ).addClass("active");
  });

  $(".cart-btn").click(() => {
    const isCartView = $(".filter").hasClass("hide-me");
    if (!isCartView) {
      $grid.arrange({ filter: ".in-cart" });
      $(".remove-something-btn").removeClass("hide-me");
      $(".cart-heading").removeClass("hide-me");
      $(".filter").addClass("hide-me");
      $(".intro").addClass("hide-me");
    } else {
      $grid.arrange({
        filter:
          filterValueArt +
          filterValueGeschmack +
          filterValueRebsorte +
          filterValueQualitätsstufe,
      });
      $(".remove-something-btn").addClass("hide-me");
      $(".cart-heading").addClass("hide-me");
      $(".filter").removeClass("hide-me");
      $(".intro").removeClass("hide-me");
    }
  });
};
