[
  {
    "bezeichnung": "Rivaner",
    "id": "1",
    "status": "ausgetrunken",
    "jahrgang": "2022",
    "preis": 6.9,
    "alkoholgehalt": "12,0 % vol",
    "restzucker": "6,4 g/l",
    "saure": "4,8 g/l",
    "geschmackType": "trocken",
    "geschmack": "trocken",
    "art": "Weißwein",
    "productType": "weiss",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 9.2,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Rivaner",
    "qualitat": "Deutscher Qualitätswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Lecker, frisch und säuremild.",
    "image": "01_2023",
    "glasImage": "glas1"
  },
  {
    "bezeichnung": "Elbling",
    "id": "2",
    "status": "sichtbar",
    "jahrgang": "2023",
    "preis": 7.9,
    "alkoholgehalt": "11,5 % vol",
    "restzucker": "3,5 g/l",
    "saure": "7,4 g/l",
    "geschmackType": "trocken",
    "geschmack": "trocken",
    "art": "Weißwein",
    "productType": "weiss",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 10.53,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Elbling  ",
    "qualitat": "Deutscher Qualitätswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Der Elbling wurde bereits von den Römern an der Mosel angebaut – heute ist er eine Rarität.",
    "banner": "NEU",
    "bannerFarbe": "red",
    "image": "02_2024",
    "glasImage": "glas1"
  },
  {
    "bezeichnung": "Riesling Hochgewächs",
    "id": "3",
    "status": "sichtbar",
    "jahrgang": "2023",
    "preis": 8.5,
    "alkoholgehalt": "12,0 % vol",
    "restzucker": "7,2 g/l",
    "saure": "7,3 g/l",
    "geschmackType": "trocken",
    "geschmack": "trocken",
    "art": "Weißwein",
    "productType": "weiss",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 11.33,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Riesling",
    "qualitat": "Deutscher Qualitätswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Säurebetont, klar und fruchtig –  unser Klassiker!",
    "banner": "NEU",
    "bannerFarbe": "red",
    "image": "03_2024",
    "glasImage": "glas1"
  },
  {
    "bezeichnung": "Chardonnay",
    "id": "4",
    "status": "sichtbar",
    "jahrgang": "2022",
    "preis": 9,
    "alkoholgehalt": "12,5 % vol",
    "restzucker": "6,9 g/l",
    "saure": "5,2 g/l",
    "geschmackType": "trocken",
    "geschmack": "trocken",
    "art": "Weißwein",
    "productType": "weiss",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 12,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Chardonnay",
    "qualitat": "Deutscher Qualitätswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Der Chardonnay ist überall auf der Welt zuhause. ",
    "image": "04_2023",
    "glasImage": "glas1"
  },
  {
    "bezeichnung": "Grauburgunder",
    "id": "5",
    "status": "sichtbar",
    "jahrgang": "2023",
    "preis": 10,
    "alkoholgehalt": "11,0 % vol",
    "restzucker": "8,0 g/l",
    "saure": "5,7 g/l",
    "geschmackType": "trocken",
    "geschmack": "trocken",
    "art": "Weißwein",
    "productType": "weiss",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 13.33,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Grauburgunder",
    "qualitat": "Deutscher Qualitätswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Unser beliebtester Burgunder - ab sofort in der schlanken Bordeauxflasche.",
    "banner": "NEU",
    "bannerFarbe": "red",
    "image": "05_2024",
    "glasImage": "glas1"
  },
  {
    "bezeichnung": "Blanc de Noir",
    "id": "6",
    "status": "sichtbar",
    "jahrgang": "2023",
    "preis": 11.8,
    "alkoholgehalt": "11,5 % vol",
    "restzucker": "8,2 g/l",
    "saure": "6,5 g/l",
    "geschmackType": "trocken",
    "geschmack": "trocken",
    "art": "Weißwein",
    "productType": "weiss",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 15.73,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Spätburgunder",
    "qualitat": "Deutscher Qualitätswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Weißer Wein aus roten Spätburgunder - Trauben. Eine Spezialität!",
    "banner": "NEU",
    "bannerFarbe": "red",
    "image": "06_2024",
    "glasImage": "glas2"
  },
  {
    "bezeichnung": "Riesling Spätlese Cochemer Goldbäumchen",
    "id": "7",
    "status": "sichtbar",
    "jahrgang": "2023",
    "preis": 12.5,
    "alkoholgehalt": "12,0 % vol",
    "restzucker": "5,9 g/l",
    "saure": "6,2 g/l",
    "geschmackType": "trocken",
    "geschmack": "trocken",
    "art": "Weißwein",
    "productType": "weiss",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 16.67,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Riesling",
    "qualitat": "Deutscher Prädikatswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Filigran und leicht mit feiner Säure und dezentem Duft nach Kernobst.",
    "banner": "NEU",
    "bannerFarbe": "red",
    "image": "07_2024",
    "glasImage": "glas1"
  },
  {
    "bezeichnung": "Riesling Auslese Detzemer Maximiner Klosterlay",
    "id": "8",
    "status": "sichtbar",
    "jahrgang": "2023",
    "preis": 13,
    "alkoholgehalt": "12,0 % vol",
    "restzucker": "7,1 g/l",
    "saure": "7,2 g/l",
    "geschmackType": "trocken",
    "geschmack": "trocken",
    "art": "Weißwein",
    "productType": "weiss",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 17.33,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Riesling",
    "qualitat": "Deutscher Prädikatswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Harmonischer, ausgewogener Riesling für besondere Anlässe.",
    "banner": "NEU",
    "bannerFarbe": "red",
    "image": "08_2023",
    "glasImage": "glas1"
  },
  {
    "bezeichnung": "Rivaner",
    "id": "9",
    "status": "sichtbar",
    "jahrgang": "2022",
    "preis": 6.9,
    "alkoholgehalt": "12,0 % vol",
    "restzucker": "12,5 g/l",
    "saure": "4,9 g/l",
    "geschmackType": "halbtrocken",
    "geschmack": "halbtrocken",
    "art": "Weißwein",
    "productType": "weiss",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 9.2,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Rivaner",
    "qualitat": "Deutscher Qualitätswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Leckerer Brot-und Butterwein.",
    "image": "09_2024",
    "glasImage": "glas1"
  },
  {
    "bezeichnung": "Riesling Hochgewächs",
    "id": "10",
    "status": "sichtbar",
    "jahrgang": "2023",
    "preis": 8.5,
    "alkoholgehalt": "12,0 % vol",
    "restzucker": "14,9 g/l",
    "saure": "6,8 g/l",
    "geschmackType": "halbtrocken",
    "geschmack": "halbtrocken",
    "art": "Weißwein",
    "productType": "weiss",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 11.33,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Riesling",
    "qualitat": "Deutscher Qualitätswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Seit vielen Jahren unser Bestseller.",
    "banner": "NEU",
    "bannerFarbe": "red",
    "image": "10_2024",
    "glasImage": "glas1"
  },
  {
    "bezeichnung": "Weißburgunder",
    "id": "11",
    "status": "sichtbar",
    "jahrgang": "2023",
    "preis": 9,
    "alkoholgehalt": "10,5 % vol",
    "restzucker": "16,3 g/l",
    "saure": "6,3 g/l",
    "geschmackType": "halbtrocken",
    "geschmack": "halbtrocken",
    "art": "Weißwein",
    "productType": "weiss",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 12,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Weißburgunder ",
    "qualitat": "Deutscher Qualitätswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Fruchtiges Bukett, angenehme Säure.",
    "banner": "NEU",
    "bannerFarbe": "red",
    "image": "11_2024",
    "glasImage": "glas1"
  },
  {
    "bezeichnung": "Auxerrois",
    "id": "12",
    "status": "sichtbar",
    "jahrgang": "2023",
    "preis": 11.8,
    "alkoholgehalt": "11,5 % vol",
    "restzucker": "15,0 g/l",
    "saure": "5,9 g/l",
    "geschmackType": "halbtrocken",
    "geschmack": "halbtrocken",
    "art": "Weißwein",
    "productType": "weiss",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 15.73,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Auxerrois",
    "qualitat": "Deutscher Qualitätswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Muschelkalk und ein perfektes Mikroklima verleihen dem Wein eine grazile Säure, feingliedrige Mineralik und sanfte Frucht.",
    "banner": "NEU",
    "bannerFarbe": "red",
    "image": "12_2024",
    "glasImage": "glas1"
  },
  {
    "bezeichnung": "Blanc de Noir",
    "id": "13",
    "status": "sichtbar",
    "jahrgang": "2023",
    "preis": 11.8,
    "alkoholgehalt": "11,5 % vol",
    "restzucker": "14,1 g/l",
    "saure": "7,0 g/l",
    "geschmackType": "halbtrocken",
    "geschmack": "halbtrocken",
    "art": "Weißwein",
    "productType": "weiss",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 15.73,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Auxerrois",
    "qualitat": "Deutscher Qualitätswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Der angenehm süßliche Blüdenduft macht Lust auf den ersten Schluck.",
    "banner": "NEU",
    "bannerFarbe": "red",
    "image": "13_2024",
    "glasImage": "glas1"
  },
  {
    "bezeichnung": "Riesling Kabinett Merler Stephansberg",
    "id": "14",
    "status": "sichtbar",
    "jahrgang": "2017",
    "preis": 12.5,
    "alkoholgehalt": "9,5 % vol",
    "restzucker": "15,7 g/l",
    "saure": "7,5 g/l",
    "geschmackType": "halbtrocken",
    "geschmack": "halbtrocken",
    "art": "Weißwein",
    "productType": "weiss",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 16.67,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Auxerrois",
    "qualitat": "Deutscher Qualitätswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Ein wunderbar gereifter Riesling mit leichter Petrolnote.",
    "banner": "NEU",
    "bannerFarbe": "red",
    "image": "14_2024",
    "glasImage": "glas1"
  },
  {
    "bezeichnung": "Riesling Spätlese Klüsserather St. Michael",
    "id": "15",
    "status": "sichtbar",
    "jahrgang": "2023",
    "preis": 10,
    "alkoholgehalt": "10,0 % vol",
    "restzucker": "17,4 g/l",
    "saure": "7,4 g/l",
    "geschmackType": "halbtrocken",
    "geschmack": "halbtrocken",
    "art": "Weißwein",
    "productType": "weiss",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 13.33,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Riesling",
    "qualitat": "Deutscher Prädikatswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Ein reichhaltiges blumiges Bouquet paart sich mit eleganten Fruchtnoten aus Quitten und Birnen.",
    "banner": "NEU",
    "bannerFarbe": "red",
    "image": "15_2024",
    "glasImage": "glas1"
  },
  {
    "bezeichnung": "Riesling Spätlese Cochemer Rosenberg",
    "id": "16",
    "status": "sichtbar",
    "jahrgang": "2023",
    "preis": 12.5,
    "alkoholgehalt": "11,5 % vol",
    "restzucker": "16,6 g/l",
    "saure": "7,4 g/l",
    "geschmackType": "halbtrocken",
    "geschmack": "halbtrocken",
    "art": "Weißwein",
    "productType": "weiss",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 16.67,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Riesling",
    "qualitat": "Deutscher Prädikatswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Hochkonzentrierter Wein mit elegantem Aroma von gelben Früchte, frischer Zitrus und Pfirsich, ein Hauch von Mango.",
    "banner": "NEU",
    "bannerFarbe": "red",
    "image": "16_2023",
    "glasImage": "glas1"
  },
  {
    "bezeichnung": "Gewürztraminer",
    "id": "17",
    "status": "sichtbar",
    "jahrgang": "2023",
    "preis": 7.9,
    "alkoholgehalt": "12 % vol",
    "restzucker": "21,1 g/l",
    "saure": "6,2 g/l",
    "geschmackType": "feinherb",
    "geschmack": "feinherb",
    "art": "Weißwein",
    "productType": "weiss",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 10.53,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Gewürztraminer",
    "qualitat": "Deutscher Qualitätswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Unangepasst und eigenwillig mit unverwechselbaren Rosenaroma. \r\nKein Wein für jeden Geschmack.",
    "banner": "NEU",
    "bannerFarbe": "red",
    "image": "17_2023",
    "glasImage": "glas1"
  },
  {
    "bezeichnung": "Blanc de Noir",
    "id": "18",
    "status": "sichtbar",
    "jahrgang": "2022",
    "preis": 11.8,
    "alkoholgehalt": "10,5 % vol",
    "restzucker": "20,1 g/l",
    "saure": "6,0 g/l",
    "geschmackType": "feinherb",
    "geschmack": "feinherb",
    "art": "Weißwein",
    "productType": "weiss",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 15.73,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Spätburgunder",
    "qualitat": "Deutscher Qualitätswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Angenehm süßlicher Blütenduft macht Lust auf den ersten Schluck. ",
    "image": "19_2023",
    "glasImage": "glas2"
  },
  {
    "bezeichnung": "Riesling Hochgewächs",
    "id": "19",
    "status": "sichtbar",
    "jahrgang": "2023",
    "preis": 8.5,
    "alkoholgehalt": "10,5 % vol",
    "restzucker": "26,6 g/l",
    "saure": "7,5 g/l",
    "geschmackType": "feinherb",
    "geschmack": "feinherb",
    "art": "Weißwein",
    "productType": "weiss",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 11.33,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Riesling",
    "qualitat": "Deutscher Qualitätswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Ein wunderbarer Terrassenwein, geschmeidig und lange auf der Zunge präsent.",
    "banner": "NEU",
    "bannerFarbe": "red",
    "image": "19_2024",
    "glasImage": "glas1"
  },
  {
    "bezeichnung": "Riesling Auslese Klüsserather St. Michael",
    "id": "20",
    "status": "sichtbar",
    "jahrgang": "2023",
    "preis": 13,
    "alkoholgehalt": "10,5 % vol",
    "restzucker": "22,6 g/l",
    "saure": "6,9 g/l",
    "geschmackType": "feinherb",
    "geschmack": "feinherb",
    "art": "Weißwein",
    "productType": "weiss",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 17.33,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Riesling",
    "qualitat": "Deutscher Prädikatswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Ein Riesling aus hochreifen Trauben mit großem Reifepotenzial.",
    "banner": "NEU",
    "bannerFarbe": "red",
    "image": "20_2024",
    "glasImage": "glas1"
  },
  {
    "bezeichnung": "Müller-Thurgau",
    "id": "21",
    "status": "sichtbar",
    "jahrgang": "2023",
    "preis": 6.9,
    "alkoholgehalt": "10 % vol",
    "restzucker": "36,8 g/l",
    "saure": "6,7 g/l",
    "geschmackType": "lieblich",
    "geschmack": "lieblich",
    "art": "Weißwein",
    "productType": "weiss",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 9.2,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Müller-Thurgau",
    "qualitat": "Deutscher Qualitätswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Süfiger und säuremilder Zechwein.",
    "banner": "NEU",
    "bannerFarbe": "red",
    "image": "21_2024",
    "glasImage": "glas1"
  },
  {
    "bezeichnung": "Kerner",
    "id": "22",
    "status": "sichtbar",
    "jahrgang": "2023",
    "preis": 7.9,
    "alkoholgehalt": "9,5 % vol",
    "restzucker": "45,9 g/l",
    "saure": "6,3 g/l",
    "geschmackType": "lieblich",
    "geschmack": "lieblich",
    "art": "Weißwein",
    "productType": "weiss",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 10.53,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Kerner",
    "qualitat": "Deutscher Qualitätswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Saftiger Schoppenwein.",
    "banner": "NEU",
    "bannerFarbe": "red",
    "image": "22_2024",
    "glasImage": "glas1"
  },
  {
    "bezeichnung": "Bacchus",
    "id": "23",
    "status": "sichtbar",
    "jahrgang": "2023",
    "preis": 7.9,
    "alkoholgehalt": "9,5 % vol",
    "restzucker": "55,8 g/l",
    "saure": "7,0 g/l",
    "geschmackType": "lieblich",
    "geschmack": "lieblich",
    "art": "Weißwein",
    "productType": "weiss",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 10.53,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Bacchus",
    "qualitat": "Deutscher Qualitätswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Intensiver Geschmack, der an getrocknete Aprikose erinnert.",
    "banner": "NEU",
    "bannerFarbe": "red",
    "image": "23_2024",
    "glasImage": "glas1"
  },
  {
    "bezeichnung": "Riesling Spätlese Cochemer Goldbäumchen",
    "id": "24",
    "status": "sichtbar",
    "jahrgang": "2023",
    "preis": 12.5,
    "alkoholgehalt": "9 % vol",
    "restzucker": "42,4 g/l",
    "saure": "8,0 g/l",
    "geschmackType": "lieblich",
    "geschmack": "lieblich",
    "art": "Weißwein",
    "productType": "weiss",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 16.67,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Riesling",
    "qualitat": "Deutscher Prädikatswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Würziger und mineralischer Wein mit leichter Zitrusnote.",
    "banner": "NEU",
    "bannerFarbe": "red",
    "image": "24_2023",
    "glasImage": "glas1"
  },
  {
    "bezeichnung": "Riesling Spätlese Detzemer Würzgarten",
    "id": "25",
    "status": "sichtbar",
    "jahrgang": "2023",
    "preis": 10,
    "alkoholgehalt": "8,5 % vol",
    "restzucker": "53,4 g/l",
    "saure": "7,4 g/l",
    "geschmackType": "lieblich",
    "geschmack": "lieblich",
    "art": "Weißwein",
    "productType": "weiss",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 13.33,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Riesling",
    "qualitat": "Deutscher Prädikatswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Lecker süße Riesling Spätlese.",
    "banner": "NEU",
    "bannerFarbe": "red",
    "image": "25_2024",
    "glasImage": "glas1"
  },
  {
    "bezeichnung": "Riesling Auslese Klüsserather Bruderschaft",
    "id": "26",
    "status": "sichtbar",
    "jahrgang": "2023",
    "preis": 13,
    "alkoholgehalt": "8,5 % vol",
    "restzucker": "67,2 g/l",
    "saure": "7,8 g/l",
    "geschmackType": "lieblich",
    "geschmack": "lieblich",
    "art": "Weißwein",
    "productType": "weiss",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 17.33,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Riesling",
    "qualitat": "Deutscher Prädikatswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Edler, sehr konzentrierter und aromatischer Wein.",
    "banner": "NEU",
    "bannerFarbe": "red",
    "image": "26_2024",
    "glasImage": "glas1"
  },
  {
    "bezeichnung": "Riesling Auslese",
    "id": "26b",
    "status": "sichtbar",
    "jahrgang": "2011",
    "preis": 22,
    "alkoholgehalt": "11,5 % vol",
    "restzucker": "48,2 g/l",
    "saure": "6,9 g/l",
    "geschmackType": "edelsüß",
    "geschmack": "edelsüß",
    "art": "Weißwein",
    "productType": "weiss",
    "menge": 0.5,
    "einheit": "l",
    "grundpreis": 44,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Riesling",
    "qualitat": "Deutscher Prädikatswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Ein edelsüßer Wein, den Sie Schlückchen für Schlückchen, Beere für Beere genießen können.",
    "banner": "NEU",
    "bannerFarbe": "red",
    "image": "26b_2024",
    "glasImage": "glas1"
  },
  {
    "bezeichnung": "Riesling Beerenauslese",
    "id": "27",
    "status": "sichtbar",
    "jahrgang": "2018",
    "preis": 39,
    "alkoholgehalt": "8,0 % vol",
    "restzucker": "127 g/l",
    "saure": "8,2 g/l",
    "geschmackType": "edelsüß",
    "geschmack": "edelsüß",
    "art": "Weißwein",
    "productType": "weiss",
    "menge": 0.375,
    "einheit": "l",
    "grundpreis": 104,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Riesling",
    "qualitat": "Deutscher Prädikatswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Dieser Riesling ist ein Wein für besondere An-lässe. Er ist ein guter Dessertwein, passt als Be-gleiter aber auch zu Gänseleber.",
    "banner": "NEU",
    "bannerFarbe": "red",
    "image": "27_2024",
    "glasImage": "glas1"
  },
  {
    "bezeichnung": "Riesling Eiswein",
    "id": "28",
    "status": "sichtbar",
    "jahrgang": "2021",
    "preis": 46,
    "alkoholgehalt": "7,0 % vol",
    "restzucker": "180 g/l",
    "saure": "15,9 g/l",
    "geschmackType": "edelsüß",
    "geschmack": "edelsüß",
    "art": "Weißwein",
    "productType": "weiss",
    "menge": 0.375,
    "einheit": "l",
    "grundpreis": 122.67,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Riesling",
    "qualitat": "Deutscher Prädikatswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Extrem konzentriert und selten - eine besondere Rarität. Voll im Saft, konzentriert, dicht und vollmundig verkörpert er pure Riesling-Liebe. Die hohe Restsüße und die exzellent präsente Säure sind eine Herausforderung für den Gau-men. Ein Feuerwerk von Waldhonig und blutigen Pfirsichnoten.",
    "banner": "NEU",
    "bannerFarbe": "red",
    "image": "28_2024",
    "glasImage": "glas1"
  },
  {
    "bezeichnung": "Riesling Trockenbeerenauslese",
    "id": "29",
    "status": "sichtbar",
    "jahrgang": "2018",
    "preis": 65,
    "alkoholgehalt": "6,0 % vol",
    "restzucker": "361,1 g/l",
    "saure": "10,9 g/l",
    "geschmackType": "edelsüß",
    "geschmack": "edelsüß",
    "art": "Weißwein",
    "productType": "weiss",
    "menge": 0.375,
    "einheit": "l",
    "grundpreis": 173.33,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Riesling",
    "qualitat": "Deutscher Prädikatswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Trockenbeerenauslese – ein Wein der höchsten Qualitätsklasse. Botrytis sorgt für die Durchlässigkeit der Haut, die für die Austrocknung der Traube sorgt. Diese bleiben so lange am Stock, bis sie fast schon rosinenförmig eingeschrumpelt sind.",
    "banner": "NEU",
    "bannerFarbe": "red",
    "image": "29_2024",
    "glasImage": "glas1"
  },
  {
    "bezeichnung": "Rotling",
    "id": "30",
    "status": "ausgetrunken",
    "jahrgang": "2022",
    "preis": 8.5,
    "alkoholgehalt": "11,5 % vol",
    "restzucker": "4,5 g/l",
    "saure": "5,2 g/l",
    "geschmackType": "trocken",
    "geschmack": "trocken",
    "art": "Rotling",
    "productType": "rotling",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 11.33,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "qualitat": "Deutscher Qualitätswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Leckerer Duft nach roten Früchten und Frühlingswiese.",
    "image": "31_2023",
    "glasImage": "glas2"
  },
  {
    "bezeichnung": "Rotling",
    "id": "31",
    "status": "sichtbar",
    "jahrgang": "2023",
    "preis": 8.5,
    "alkoholgehalt": "11,5 % vol",
    "restzucker": "19,5 g/l",
    "saure": "5,6 g/l",
    "geschmackType": "feinherb",
    "geschmack": "feinherb",
    "art": "Rotling",
    "productType": "rotling",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 11.33,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "qualitat": "Deutscher Qualitätswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Frisch fruchtiger Frühlingswein.",
    "banner": "NEU",
    "bannerFarbe": "red",
    "image": "32_2023",
    "glasImage": "glas2"
  },
  {
    "bezeichnung": "Rosé",
    "id": "32",
    "status": "sichtbar",
    "jahrgang": "2022",
    "preis": 8.5,
    "alkoholgehalt": "11,5 % vol",
    "restzucker": "8,4 g/l",
    "saure": "7,3 g/l",
    "geschmackType": "trocken",
    "geschmack": "trocken",
    "art": "Rosé",
    "productType": "rose",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 11.33,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "qualitat": "Deutscher Qualitätswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Feinfruchtig, beerig, lebendig und frisch.",
    "image": "32_2024",
    "glasImage": "glas2"
  },
  {
    "bezeichnung": "Rosé",
    "id": "33",
    "status": "sichtbar",
    "jahrgang": "2023",
    "preis": 8.5,
    "alkoholgehalt": "10 % vol",
    "restzucker": "34 g/l",
    "saure": "5,7 g/l",
    "geschmackType": "lieblich",
    "geschmack": "lieblich",
    "art": "Rosé",
    "productType": "rose",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 11.33,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "qualitat": "Deutscher Qualitätswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Feinfruchtig, beerig, lebendig und frisch.",
    "banner": "NEU",
    "bannerFarbe": "red",
    "image": "33_2024",
    "glasImage": "glas2"
  },
  {
    "bezeichnung": "Regent",
    "id": "34",
    "status": "sichtbar",
    "jahrgang": "2023",
    "preis": 7.9,
    "alkoholgehalt": "12 % vol",
    "restzucker": "6,4 g/l",
    "saure": "4,0 g/l",
    "geschmackType": "trocken",
    "geschmack": "trocken",
    "art": "Rotwein",
    "productType": "rot",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 10.53,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Dornfelder",
    "qualitat": "Deutscher Qualitätswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Ein kräftiger Rotwein mit Aromen von Kirschen und Johannisbeeren. ",
    "banner": "NEU",
    "bannerFarbe": "red",
    "image": "34_2024",
    "glasImage": "glas3"
  },
  {
    "bezeichnung": "Dornfelder",
    "id": "35",
    "status": "sichtbar",
    "jahrgang": "2023",
    "preis": 7.9,
    "alkoholgehalt": "11,5 % vol",
    "restzucker": "12,3 g/l",
    "saure": "5,4 g/l",
    "geschmackType": "halbtrocken",
    "geschmack": "halbtrocken",
    "art": "Rotwein",
    "productType": "rot",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 10.53,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Dornfelder",
    "qualitat": "Deutscher Qualitätswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Überzeugt mit einer ausgeprägten Frucht.",
    "banner": "NEU",
    "bannerFarbe": "red",
    "image": "35_2024",
    "glasImage": "glas3"
  },
  {
    "bezeichnung": "Dornfelder",
    "id": "36",
    "status": "sichtbar",
    "jahrgang": "2021",
    "preis": 7.9,
    "alkoholgehalt": "10,5 % vol",
    "restzucker": "37,7 g/l",
    "saure": "6,7 g/l",
    "geschmackType": "lieblich",
    "geschmack": "lieblich",
    "art": "Rotwein",
    "productType": "rot",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 10.53,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Dornfelder",
    "qualitat": "Deutscher Qualitätswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Aroma erinnert an rote Waldbeeren.",
    "image": "36_2023",
    "glasImage": "glas3"
  },
  {
    "bezeichnung": "Spätburgunder Klüsserrather St. Michael",
    "id": "37",
    "status": "sichtbar",
    "jahrgang": "2022",
    "preis": 12.5,
    "alkoholgehalt": "12,5 % vol",
    "restzucker": "5,3 g/l",
    "saure": "4,8 g/l",
    "geschmackType": "trocken",
    "geschmack": "trocken",
    "art": "Rotwein",
    "productType": "rot",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 16.67,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Spätburgunder",
    "qualitat": "Deutscher Qualitätswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Ein weicher Duft getragen von Aromen nach reifen Pflaumen und rauchigen Noten. ",
    "image": "37_2024",
    "glasImage": "glas4"
  },
  {
    "bezeichnung": "Spätburgunder Cochemer Goldbäumchen",
    "id": "38",
    "status": "sichtbar",
    "jahrgang": "2022",
    "preis": 12.5,
    "alkoholgehalt": "12,5 % vol",
    "restzucker": "15,4 g/l",
    "saure": "5,1 g/l",
    "geschmackType": "halbtrocken",
    "geschmack": "halbtrocken",
    "art": "Rotwein",
    "productType": "rot",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 16.67,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Spätburgunder",
    "qualitat": "Deutscher Qualitätswein",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Intensives Kirscharoma, kräftig und vollmundig. Im Eichenfass gereift",
    "image": "38_2024",
    "glasImage": "glas4"
  },
  {
    "bezeichnung": "Secco Rosé",
    "id": "41",
    "status": "sichtbar",
    "preis": 7.9,
    "alkoholgehalt": "10,5 % vol",
    "restzucker": "26,0 g/l",
    "geschmackType": "trocken",
    "geschmack": "trocken",
    "art": "Secco",
    "productType": "rose",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 10.53,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "qualitat": "Deutscher Perlwein",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Sommerlich fruchtige Erfrischung.",
    "image": "41_2023"
  },
  {
    "bezeichnung": "Riesling Sekt der Mosel",
    "id": "42",
    "status": "sichtbar",
    "jahrgang": "2022",
    "preis": 14,
    "alkoholgehalt": "12,5 % vol",
    "restzucker": "12,0 g/l",
    "geschmackType": "trocken",
    "geschmack": "extratrocken",
    "art": "Sekt",
    "productType": "weiss",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 18.67,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Riesling",
    "qualitat": "Deutscher Sekt b.A.",
    "herkunft": "Mosel",
    "allergene": "Enthält Sulfite",
    "sonstiges": "Elegant und prickelnd.",
    "image": "42_2023"
  },
  {
    "bezeichnung": "Likör vom roten Weinbergpfirsisch",
    "id": "44",
    "status": "sichtbar",
    "preis": 15.5,
    "alkoholgehalt": "18 % vol",
    "art": "Likör",
    "productType": "sonstige",
    "menge": 0.5,
    "einheit": "l",
    "grundpreis": 31,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "sonstiges": "Pur genießen oder als Kir mit trockenem Rieslingsekt - einfach lecker!",
    "image": "44_2023"
  },
  {
    "bezeichnung": "Trester Brand vom Mosel-Riesling",
    "id": "45",
    "status": "sichtbar",
    "preis": 15.5,
    "alkoholgehalt": "40 % vol",
    "art": "Brand",
    "productType": "sonstige",
    "menge": 0.5,
    "einheit": "l",
    "grundpreis": 31,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "sonstiges": "Klar und aromatisch.",
    "image": "45_2023"
  },
  {
    "bezeichnung": "Hefe Brand vom Mosel-Riesling",
    "id": "46",
    "status": "sichtbar",
    "preis": 15.5,
    "alkoholgehalt": "40 % vol",
    "art": "Brand",
    "productType": "sonstige",
    "menge": 0.5,
    "einheit": "l",
    "grundpreis": 31,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "sonstiges": "Milder aromatischer Hefeschnaps.",
    "image": "46_2023"
  },
  {
    "bezeichnung": "Traubensaft weiß",
    "id": "51",
    "status": "sichtbar",
    "preis": 5,
    "art": "Alkoholfreies",
    "productType": "sonstige",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 6.67,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Scheurebe",
    "zutaten": "Traubensaft, Säuerungsmittel: Citronensäure, Antioxidationsmittel: Ascorbinsäure",
    "sonstiges": "Der Renner bei Kindern.",
    "image": "51_2023",
    "glasImage": "glas1"
  },
  {
    "bezeichnung": "Traubensaft rot",
    "id": "52",
    "status": "sichtbar",
    "preis": 5,
    "art": "Alkoholfreies",
    "productType": "sonstige",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 6.67,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Dornfelder",
    "zutaten": "Traubensaft, Säuerungsmittel: Citronensäure, Antioxidationsmittel: Ascorbinsäure",
    "sonstiges": "Sehr lecker auch als Schorle.",
    "image": "52_2023",
    "glasImage": "glas4"
  },
  {
    "bezeichnung": "BIO Traubensecco rosé",
    "id": "54",
    "status": "sichtbar",
    "preis": 7.9,
    "art": "Alkoholfreies",
    "productType": "sonstige",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 10.53,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "zutaten": "BIO Traubensaft, Kohlensäure, Säuerungsmittel: Citronensäure",
    "sonstiges": "Bio-Traubensaft mit zugesetzter Kohlensäure. Ein lecker sprudelndes Trinkvergnügen.",
    "image": "54_2023",
    "glasImage": "glas2"
  },
  {
    "bezeichnung": "Riesling Wein entakoholisiert",
    "id": "55",
    "status": "sichtbar",
    "preis": 7.9,
    "alkoholgehalt": "< 0,5 % vol",
    "art": "Alkoholfreies",
    "productType": "sonstige",
    "menge": 0.75,
    "einheit": "l",
    "grundpreis": 10.53,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "rebsorte": "Riesling",
    "allergene": "Antioxidationsmittel: Schwefeldioxid",
    "zutaten": "alkoholfreier Wein, rektifiziertes Traumenmostkonzentrat, Kohlensäure, Konservierungsstoff: *Schwefeldioxid*",
    "sonstiges": "Ein edler, entalkoholisierter Rieslingwein",
    "image": "55_2023",
    "glasImage": "glas1"
  },
  {
    "bezeichnung": "Weingelee rot",
    "id": "61",
    "status": "sichtbar",
    "preis": 4.8,
    "art": "Gelee",
    "productType": "sonstige",
    "menge": 225,
    "einheit": "g",
    "grundpreis": 2.1333333333333333,
    "grundpreisEinheit": "100 g",
    "packageSpace": 0.5,
    "zutaten": "Wein 55%, Zucker, Geliermittel Pektin, Säuerungsmittel Zitronensäure",
    "sonstiges": "Klassisch aufs Brot oder zum Verfeinern von Soßen und Pastagerichten.",
    "image": "61_2024"
  },
  {
    "bezeichnung": "Roter Weinbergpfirsischgelee",
    "id": "62",
    "status": "ausgetrunken",
    "preis": 4.8,
    "art": "Gelee",
    "productType": "sonstige",
    "menge": 225,
    "einheit": "g",
    "grundpreis": 2.1333333333333333,
    "grundpreisEinheit": "100 g",
    "packageSpace": 0.5,
    "zutaten": "Pfirsichsaft 55%, Zucker, Geliermittel Pektin, Säuerungsmittel Zitronensäure",
    "sonstiges": "Süßer Genuss auf Croissants, lecker auch mit Camenbert.",
    "image": "62_2024"
  },
  {
    "bezeichnung": "Traubenkernöl",
    "id": "63",
    "status": "sichtbar",
    "preis": 11,
    "art": "Öl",
    "productType": "sonstige",
    "menge": 250,
    "einheit": "ml",
    "grundpreis": 44,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "zutaten": "Traubenkernöl",
    "sonstiges": "Nussiger Geschmack, hervorragend für die Zubereitung von Salaten und Dips.",
    "image": "63_2024"
  },
  {
    "bezeichnung": "Fruchtessigmix vom roten Weinbergpfirsisch",
    "id": "64",
    "status": "sichtbar",
    "preis": 10,
    "art": "Essig",
    "productType": "sonstige",
    "menge": 250,
    "einheit": "ml",
    "grundpreis": 40,
    "grundpreisEinheit": "l",
    "packageSpace": 1,
    "zutaten": "Wasser, Weißweinessig 10% Säure, Zucker, Pfirsichsaftkonzentrat, Aroma, Aroniasaftkonzentrat, Xanthan, Kaliumsorbat",
    "sonstiges": "Herb-fruchtiger Geschmack für Dressings, Desserts oder Smoothies.",
    "image": "64_2024"
  },
  {
    "bezeichnung": "Riesling Senf aus Monschau",
    "id": "65",
    "status": "sichtbar",
    "preis": 9.5,
    "art": "Senf",
    "productType": "sonstige",
    "menge": 100,
    "einheit": "ml",
    "grundpreis": 9.5,
    "grundpreisEinheit": "100 ml",
    "packageSpace": 0.5,
    "zutaten": "Wasser, *Senfmehl*, Branntweinessig, 15% Weingelee (Weißwein, Zucker, Geliermittel Pektin, Säurungsmittel Citronensäure, Plfanzöl (Kokos-, Palm-), Konservierungsstoff Kaliumssorbat), Zucker, Kochsalz, Weißwein, 2% Rieslingsekt, natürliches Pfirsischaroma",
    "sonstiges": "Feinmilder und edler Senf.",
    "image": "65_2024"
  },
  {
    "bezeichnung": "Ur-Senf aus Monschau",
    "id": "66",
    "status": "sichtbar",
    "preis": 9.5,
    "art": "Senf",
    "productType": "sonstige",
    "menge": 100,
    "einheit": "ml",
    "grundpreis": 9.5,
    "grundpreisEinheit": "100 ml",
    "packageSpace": 0.5,
    "zutaten": "Wasser, *Senfmehl*, Branntweinessig, Zucker, Salinensalz ",
    "sonstiges": "Der Klassiker, passt zu allen Speisen.",
    "image": "65_2024"
  },
  {
    "bezeichnung": "Feigensenf aus Monschau",
    "id": "67",
    "status": "sichtbar",
    "preis": 9.5,
    "art": "Senf",
    "productType": "sonstige",
    "menge": 100,
    "einheit": "ml",
    "grundpreis": 9.5,
    "grundpreisEinheit": "100 ml",
    "packageSpace": 0.5,
    "zutaten": "Wasser, *Senfmehl (33%)*, Branntweinessig, Zucker, Feigen (9%), Speisesalz, natürliches Aroma, Geliermittel Pektin, Säuerungsmittel Citronensäure - Kann in Spuren *Weizenstärke* enthalten",
    "sonstiges": "Zarte Schärfe gepaart mit exotischem Feigenaroma, bekannt aus unserem Käsepicknick.",
    "image": "65_2024"
  }
]