import weine from "../data/weine.json";
import productsImg from "../img/products/*.jpg";
import productTypeImg from "../img/productType/*.png";
import wappen from "../img/wappen.svg";
import { Storage, Util } from "./util.js";
import { initFilter } from "./filter.js";

//variables
const clearCartBtn = document.querySelector(".clear-cart");
const shipping = document.querySelector(".shipping");
const cartItems = document.querySelector(".cart-items");
const cartTotal = document.querySelector(".cart-total");
const productsDOM = document.querySelector(".products-here");

//cart
let cart = [];

//display products
class UI {
  displayProducts(products) {
    let result = "";
    products.forEach((product) => {
      //if (product.status === "unsichtbar") return;
      // items are now deleted from json array in scripts/excel2json.js
      // less data sent to customer, no bugs when adding to cart
      result += `
                <article id="product-${product.id}" class="flip-container id-${product.id}
                ${product.productType} ${product.jahrgang} ${product.rebsorte} ${product.status}
                ${product.qualitat} ${product.geschmackType}" 
                >`;

      if (product.status === "ausgetrunken")
        result += `<svg width="75%" height="100%" class="overlay-svg" viewBox="0 0 90 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;"><g><text x="3.996px" y="13.502px" style="font-family:'Merriweather-Black', 'Merriweather Black';font-weight:900;font-size:11px;fill:#870000;">Ausgetrunken!</text><path d="M89.456,1.416c0,-0.5 -0.406,-0.906 -0.906,-0.906l-87.134,-0c-0.5,-0 -0.906,0.406 -0.906,0.906l-0,16.313c-0,0.5 0.406,0.906 0.906,0.906l87.134,-0c0.5,-0 0.906,-0.406 0.906,-0.906l0,-16.313Z" style="fill:none;stroke:#870000;stroke-width:1.02px;"/></g></svg>`;

      if (product.banner)
        result += `<div class="ribbon ${product.bannerFarbe}"><span>${product.banner}</span></div>`;
      result += `
                <div class="flipper">
                  <div class="sb sb-front front">`;
      if (productsImg[product.image])
        result += `
                            <img class="sb-flasche flascheImg" src=${
                              productsImg[product.image]
                            } alt="Foto des Weines" />`;

      result += `
                    <div class="sb-front-right">
                      <header style="align-self: flex-start">`;
      if (product.jahrgang) result += `<div>${product.jahrgang}</div>`;
      if (product.bezeichnung) result += `<div>${product.bezeichnung}</div>`;
      if (product.geschmack) result += `<div>${product.geschmack}</div>`;
      result += `
                      </header>`;
      if (productTypeImg[product.glasImage])
        result += `
                    <img class="sb-glas" style="flex:1;" src="
                    ${productTypeImg[product.glasImage]}" 

                      alt="" />`;
      result += `
                    </div>
                  </div>
                  <div class="sb sb-back back">
                    <div>
                      <img class="wappen" src="${wappen}" />
                      <section>
                        <header>Steckbrief</header>
                        <aside>${product.bezeichnung}</aside>
                      </section>
                    </div>
                      <table class="table table-sm">`;
      if (product.sonstiges)
        result += `     <tr>
                          <td colspan=2>${product.sonstiges}</th>
                        </tr>`;
      if (product.inhalt)
        result += `    <tr>
                          <td colspan=2><b>Inhalt:</b><br>${product.inhalt}</th>
                        </tr>`;
      if (product.rebsorte)
        result += `     <tr>
                        <td>Rebsorte</td>
                        <th>${product.rebsorte}</th>
                        </tr>`;
      if (product.geschmack)
        result += `     <tr class="geschmack">
                        <td>Geschmack</td>
                        <th>${product.geschmack}</th>
                        </tr>`;
      if (product.jahrgang)
        result += `     <tr>
                        <td>Jahrgang</td>
                        <th>${product.jahrgang}</th>
                        </tr>`;
      if (product.art)
        result += `     <tr>
                        <td>Art</td>
                        <th>${product.art}</th>
                        </tr>`;
      if (product.id)
        result += `     <tr>
                        <td>Listennummer</td>
                        <th>${product.id}</th>
                        </tr>`;
      if (product.qualitat)
        result += `     <tr>
                          <td>Qualitätsstufe</td>
                          <th>${product.qualitat}</th>
                        </tr>`;
      if (product.alkoholgehalt)
        result += `     <tr>
                          <td>Alkoholgehalt</td>
                          <th>${product.alkoholgehalt}</th>
                        </tr>`;
      if (product.restzucker)
        result += `     <tr>
                          <td>Restzucker</td>
                          <th>${product.restzucker}</th>
                        </tr>`;
      if (product.saure)
        result += `     <tr>
                          <td>Säure</td>
                          <th>${product.saure}</th>
                        </tr>`;
      if (product.allergene)
        result += `     <tr>
                          <td>Allergene</td>
                          <th>${product.allergene}</th>
                        </tr>`;
      if (product.zutaten)
        result += `     <tr>
                                            <td>Zutaten</td>
                                            <th>${product.zutaten}</th>
                                          </tr>`;
      if (product.herkunft)
        result += `     <tr>
                        <td>Herkunft</td>
                        <th>${product.herkunft}</th>
                        </tr>`;
      if (product.menge)
        result += `     <tr>
                          <td>Menge</td>
                          <th>${parseFloat(product.menge).toLocaleString()} ${
          product.einheit
        }</th>
                        </tr>`;
      if (product.grundpreis)
        result += `     <tr>
                          <td>Grundpreis</td>
                          <th>${parseFloat(product.grundpreis).toLocaleString(
                            undefined,
                            {
                              style: "currency",
                              currency: "EUR",
                            }
                          )}/${product.grundpreisEinheit}</th>
                        </tr>`;

      result += `
                      </table>
                    </div>
                  </div>

                  <!-- Pricetag -->
                  <div id="pricetag-${product.id}" class="pricetag">
                    <i data-id=${
                      product.id
                    } class="fas fa-minus-square pricetag-btn"></i>
                    <div class="pricetag-prices">
                      <h3 class="pricetag-price">
                        <span id="pricetag-amount-${
                          product.id
                        }"></span> ${parseFloat(product.preis).toLocaleString(
        undefined,
        { style: "currency", currency: "EUR" }
      )}
                      <i data-id=${product.id} 
                          class="fas fa-trash-alt remove-something-btn hide-me pricetag-btn"></i></h3>`;

      if (product.grundpreis)
        result += `
        <P>${parseFloat(product.grundpreis).toLocaleString(undefined, {
          style: "currency",
          currency: "EUR",
        })}/${product.grundpreisEinheit}</p>`;

      result += `</div>
                    <i data-id=${product.id}
                      class="fas fa-plus-square pricetag-plus-btn pricetag-btn"></i>
                  </div>
                  <!-- Enf of pricetag -->
                </article>`;
    });
    productsDOM.innerHTML = result;
  }

  setCartValues(cart) {
    let tempTotal = 0;
    let itemsTotal = 0;
    cart.map((item) => {
      tempTotal += item.preis * item.amount;
      itemsTotal += item.amount;
      //add class for isotype filter
      document.getElementById("product-" + item.id).classList.add("in-cart");
    });
    let tempShipping = Util.calcShippingFee(cart);
    cartTotal.innerText = parseFloat(tempTotal).toLocaleString(undefined, {
      style: "currency",
      currency: "EUR",
    });
    cartItems.innerText = itemsTotal;
    shipping.innerText = parseFloat(tempShipping).toLocaleString(undefined, {
      style: "currency",
      currency: "EUR",
    });
  }

  setupAPP() {
    cart = Storage.getCart();
    // remove items from the cart that are no longer in the shop
    cart = Util.clearCartFromOldProducts(cart, weine);
    Storage.saveCart(cart);
    this.setCartValues(cart);
    if (cart.length) {
      document.getElementById("cart").classList.remove("hide-me");
    }
    cart.forEach((item) => {
      let id = item.id;
      document.getElementById("pricetag-amount-" + id).innerHTML =
        cart.find((item) => item.id === id).amount + " x";
    });
  }
  cartLogic() {
    //clear cart button (delete all)
    clearCartBtn.addEventListener("click", () => {
      this.clearCart();
    });
    //cart functionality
    let pricetagItemInCart = document.querySelectorAll(".pricetag-btn");
    pricetagItemInCart.forEach((element) => {
      element.addEventListener("click", (event) => {
        //remove button
        if (event.target.classList.contains("fa-trash-alt")) {
          this.removeItem(event.target.dataset.id);
        }
        //add item with plus button
        else if (event.target.classList.contains("fa-plus-square")) {
          let addAmount = event.target;
          let id = event.target.dataset.id;
          let inCart = cart.find((item) => item.id === id);
          if (!inCart) {
            //get Product form local Storage
            let cartItem = { ...Storage.getProduct(id), amount: 0 };
            //add product to the cart variable
            cart = [...cart, cartItem];
          }
          let tempItem = cart.find((item) => item.id === id);
          tempItem.amount++;
          Storage.saveCart(cart);
          this.setCartValues(cart);
          document.getElementById("pricetag-amount-" + id).innerHTML =
            tempItem.amount + " x";
          document.getElementById("cart").classList.remove("hide-me");
        }
        //remove item with minus button
        else if (event.target.classList.contains("fa-minus-square")) {
          let lowerAmount = event.target;
          let id = event.target.dataset.id;
          let tempItem = cart.find((item) => item.id === id);
          tempItem.amount--;
          if (tempItem.amount > 0) {
            Storage.saveCart(cart);
            this.setCartValues(cart);
            document.getElementById("pricetag-amount-" + id).innerHTML =
              tempItem.amount + " x";
          } else {
            this.removeItem(id);
          }
        }
      });
    });
  }
  clearCart() {
    let cartItems = cart.map((item) => item.id);
    cartItems.forEach((id) => this.removeItem(id));
  }
  removeItem(id) {
    cart = cart.filter((item) => item.id !== id);
    this.setCartValues(cart);
    Storage.saveCart(cart);
    //remove class for isotype filter
    document.getElementById("product-" + id).classList.remove("in-cart");
    // init(); call init only once! (otherwise elements have multiple event-handlers)
    //hide shopping bar in case its the last item
    if (!cart.length) {
      // switch cart/non cart mode (very quick and dirty!)
      if (document.getElementById("intro").classList.contains("hide-me"))
        $(".cart-btn").click();
      document.getElementById("cart").classList.add("hide-me");
    }
    // remove amount counter
    document.getElementById("pricetag-amount-" + id).innerHTML = "";
  }
}

document.addEventListener("DOMContentLoaded", () => {
  Storage.resetLocalStorageOnce();
  const ui = new UI();
  ui.displayProducts(weine);
  ui.setupAPP();
  Storage.saveProducts(weine);
  ui.cartLogic();
  initFilter();
});
